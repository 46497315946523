var orderTypeColumns = [
    {
        name: "Internal Order",
        property: "internal"
    },
    {
        name: "Offer",
        property: "offer"
    },
    {
        name: "Repair",
        property: "repair"
    },
    {
        name: "Warranty",
        property: "warranty"
    },
    {
        name: "Sale",
        property: "sale"
    },
    {
        name: "Total",
        property: "total"
    }
];

export let ResultTypes = [
    {
        id: 1,
        name: "Status (amount)",
        columns: [
            {
                name: "Status",
                property: "status"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 2,
        name: "Appointment",
        columns: [
            {
                name: "Appointment",
                property: "appointment"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 3,
        name: "Replacement vehicle",
        columns: [
            {
                name: "Recplacement vehicle",
                property: "replacementVehicle"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 4,
        name: "On location",
        columns: [
            {
                name: "On location",
                property: "onLocation"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 5,
        name: "Photo",
        columns: [
            {
                name: "Photo",
                property: "photo"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 6,
        name: "EST",
        columns: [
            {
                name: "EST",
                property: "est"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 7,
        name: "Client present",
        columns: [
            {
                name: "Client present",
                property: "clientPresent"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 8,
        name: "Washed",
        columns: [
            {
                name: "Washed",
                property: "washed"
            }
        ].concat(orderTypeColumns)
    },
    // {
    //     id: 9,
    //     name: "Top 10: Appointment made",
    //     columns: [
    //         {
    //             name: "Client",
    //             property: "client"
    //         },
    //         {
    //             name: "Orders with appointment",
    //             property: "count"
    //         }
    //     ]
    // },
    // {
    //     id: 10,
    //     name: "Top 10: No appointment made",
    //     columns: [
    //         {
    //             name: "Client",
    //             property: "client"
    //         },
    //         {
    //             name: "Orders without appointment",
    //             property: "count"
    //         }
    //     ]
    // }
    {
        id: 11,
        name: "Top 10: Number of orders per client",
        columns: [
            {
                name: "Client",
                property: "client"
            },
            {
                name: "Number of orders",
                property: "count"
            }
        ]
    },
    {
        id: 12,
        name: "Top 10: Total price orders per client",
        columns: [
            {
                name: "Client",
                property: "client"
            },
            {
                name: "Total price orders (€)",
                property: "price"
            }
        ]
    },
    {
        id: 13,
        name: "Top 10: Number of orders per machine",
        columns: [
            {
                name: "Machine",
                property: "machine"
            },
            {
                name: "Number of orders",
                property: "count"
            }
        ]
    },
    {
        id: 14,
        name: "Top 10: Total price orders per machine",
        columns: [
            {
                name: "Machine",
                property: "machine"
            },
            {
                name: "Total price orders (€)",
                property: "price"
            }
        ]
    },
    {
        id: 15,
        name: "Technicians time",
        columns: [
            {
                name: "Technician",
                property: "name"
            },
            {
                name: "Number of orders",
                property: "number_of_orders"
            },
            {
                name: "Specified hours",
                property: "specified_time",
                time: true
            },
            {
                name: "Measured hours",
                property: "measured_time",
                time: true
            }
        ]
    },
    {
        id: 17,
        name: "Parts per order",
        columns: [
            {
                name: "",
                property: "rowName"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 17,
        name: "Parts per order",
        columns: [
            {
                name: "",
                property: "rowName"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 18,
        name: "Status (price)",
        price: true,
        columns: [
            {
                name: "Status",
                property: "status"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 19,
        name: "Rentals",
        columns: [
            {
                name: "Status",
                property: "state"
            },
            {
                name: "Amount",
                property: "amount"
            },
            {
                name: "Price",
                property: "price",
                price: true
            }
        ]
    },
    {
        id: 20,
        name: "Technicians specified time per order category",
        columns: [
            {
                name: "Technician",
                property: "name"
            },
            {
                name: "Internal Order",
                property: "specified_time_internal",
                time: true
            },
            {
                name: "Offer",
                property: "specified_time_offer",
                time: true
            },
            {
                name: "Repair",
                property: "specified_time_warranty",
                time: true
            },
            {
                name: "Warranty",
                property: "specified_time_repair",
                time: true
            },
            {
                name: "Sale",
                property: "specified_time_sale",
                time: true
            },
            {
                name: "Total",
                property: "specified_time_total",
                time: true
            }
        ]
    },
    {
        id: 21,
        name: "Major maintenance",
        columns: [
            {
                name: "Major maintenance",
                property: "major_maintenance"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 22,
        name: "Minor maintenance",
        columns: [
            {
                name: "Minor maintenance",
                property: "minor_maintenance"
            }
        ].concat(orderTypeColumns)
    },
    {
        id: 23,
        name: "Repeated repair",
        columns: [
            {
                name: "Repeated repair",
                property: "repeated_repair"
            }
        ].concat(orderTypeColumns)
    }
]