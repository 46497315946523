import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import debounce from 'debounce-promise'

class Filter extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      client: null
    };

    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeClient = this.onChangeClient.bind(this);
    this.onChangeMachine = this.onChangeMachine.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangePart = this.onChangePart.bind(this);
    this.onChangeMachineCategory = this.onChangeMachineCategory.bind(this);
    this.onChangeTechnician = this.onChangeTechnician.bind(this);
    this.getClients = this.getClients.bind(this);
    this.getMachines = this.getMachines.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.getMachineCategories = this.getMachineCategories.bind(this);
    this.getParts = this.getParts.bind(this);
    this.getTechnicians = this.getTechnicians.bind(this);
    this.apply = this.apply.bind(this);
    this.reset = this.reset.bind(this);
  }

  render () {
    var clientOptions = [
      {id: "clientId1", name: "client1"},
      {id: "clientId2", name: "client2"}
    ];

    var debounceTime = 500;

    return (
      <React.Fragment>
        <h3>Filter</h3>

        <div className="row">
          <div className="col-md-2">
            <div className="form-group filter-container">
              {/* Start date */}
              <div className="row filter-row">
                <label className="control-label">
                  Start date
                </label>
                <div className='input-group date'>
                  <input type="date" className="form-control filter-date" value={this.state.startDate} onChange={this.onChangeStartDate}></input>
                </div>
              </div>

              {/* End date */}
              <div className="row filter-row">
                <label className="control-label">
                  End date
                </label>
                <div className='input-group date'>
                  <input type="date" className="form-control filter-date" value={this.state.endDate} onChange={this.onChangeEndDate}></input>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group filter-container">
              {/* Client */}
              <div className="row filter-row">
                <label className="control-label">
                  Client
                </label>
                <div className='input-group'>
                  <AsyncSelect
                    value={this.state.clients}
                    placeholder='Name'
                    isMulti
                    loadOptions={debounce(this.getClients, debounceTime)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onChange={this.onChangeClient}
                    className="filter-select"
                  >
                  </AsyncSelect>
                </div>
              </div>
              
              {/* Part */}
              <div className="row filter-row">
                <label className="control-label">
                  Part
                </label>
                <div className='input-group'>
                  <AsyncSelect
                    value={this.state.parts}
                    placeholder="Number"
                    isMulti
                    loadOptions={debounce(this.getParts, debounceTime)}
                    getOptionLabel={option => option.number}
                    getOptionValue={option => option.number}
                    onChange={this.onChangePart}
                    className="filter-select"
                  >
                  </AsyncSelect>
                </div>
              </div>

              {/* Technician */}
              <div className="row filter-row">
                <label className="control-label">
                  Technician
                </label>
                <div className='input-group'>
                  <AsyncSelect
                    value={this.state.technicians}
                    placeholder="Number or name"
                    isMulti
                    loadOptions={debounce(this.getTechnicians, debounceTime)}
                    getOptionLabel={option => `${option.number} - ${option.name}`}
                    getOptionValue={option => option.id}
                    onChange={this.onChangeTechnician}
                    className="filter-select"
                  >
                  </AsyncSelect>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group filter-container">
              {/* Machine */}
              <div className="row filter-row filter-row-machine">
                <label className="control-label">
                  Machine
                </label>
                <AsyncSelect
                  value={this.state.machines}
                  placeholder="VIN"
                  isMulti
                  loadOptions={debounce(this.getMachines, debounceTime)}
                  getOptionLabel={option => option.vin}
                  getOptionValue={option => option.id}
                  onChange={this.onChangeMachine}
                  className="filter-select"
                ></AsyncSelect>
                <AsyncSelect
                  value={this.state.brands}
                  placeholder="Brand"
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={debounce(this.getBrands, debounceTime)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  onChange={this.onChangeBrand}
                  className="filter-select"
                ></AsyncSelect>
                <AsyncSelect
                  value={this.state.machineCategories}
                  placeholder="Type"
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={debounce(this.getMachineCategories, debounceTime)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  onChange={this.onChangeMachineCategory}
                  className="filter-select"
                ></AsyncSelect>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-right">
          <button type="button" className="btn btn-default" onClick={this.reset}>Reset</button>
          <button type="button" className="btn btn-default" onClick={this.apply}>Apply</button>
        </div>
      </React.Fragment>
    );
  }

  onChangeStartDate(event) {
    this.setState({ startDate: event.target.value }, () => this.propagateToParent());
  }

  onChangeEndDate(event) {
    this.setState({ endDate: event.target.value }, () => this.propagateToParent());
  }

  onChangeClient(clients) {
    this.setState({ clients: clients }, () => this.propagateToParent());
  }

  onChangeMachine(machines) {
    this.setState({ machines: machines }, () => this.propagateToParent());
  }

  onChangeBrand(brands) {
    this.setState({ brands: brands }, () => this.propagateToParent());
  }

  onChangeMachineCategory(categories) {
    this.setState({ machineCategories: categories }, () => this.propagateToParent());
  }

  onChangePart(parts) {
    this.setState({ parts: parts }, () => this.propagateToParent());
  }

  onChangeTechnician(technicians) {
    this.setState({ technicians: technicians }, () => this.propagateToParent());
  }

  getClients(input) {
    var url = '/statistics/clients/' + input
    return this.getData(url);
  }

  getMachines(input) {
    var url = '/statistics/machines/' + input
    return this.getData(url);
  }

  getBrands(input='') {
    var url = '/statistics/machine_brands/' + input
    return this.getData(url);
  }

  getMachineCategories(input='') {
    var url = '/statistics/machine_categories/' + input
    return this.getData(url);
  }

  getParts(input) {
    var url = '/statistics/parts/' + input
    return this.getData(url);
  }

  getTechnicians(input) {
    var url = '/statistics/technicians/' + input
    return this.getData(url);
  }

  apply() {
    this.props.onApply();
  }

  reset() {
    this.setState({ 
      startDate: "",
      endDate: "",
      clients: null,
      machines: null,
      brands: null,
      machineCategories: null,
      parts: null,
      technicians: null
    });

    this.props.propagateToParent();
    this.props.onReset();
  }

  getData(url) {
    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';
      xhr.onload = function() {
          var status = xhr.status;
          if (status === 200) {
            resolve(xhr.response);
          } else {
            //callback(status, xhr.response);
          }
      }; 
      xhr.send();
    });
  }

  propagateToParent(){
    var filterData = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      clients: this.state.clients ? this.state.clients.map(x => x.id) : undefined,
      machines: this.state.machines ? this.state.machines.map(x => x.id) : undefined,
      brands: this.state.brands ? this.state.brands.map(x => x.name) : undefined,
      machineCategories: this.state.machineCategories ? this.state.machineCategories.map(x => x.name) : undefined,
      parts: this.state.parts ? this.state.parts.map(x => x.number) : undefined,
      technicians: this.state.technicians ? this.state.technicians.map(x => x.id) : undefined
    }

    this.props.onChangeHandler(filterData);
  }
}

export default Filter
