import React from "react"
import PropTypes from "prop-types"
class ResultsTableColumnHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClickColumnHeader = this.onClickColumnHeader.bind(this);
    this.getSortDirection = this.getSortDirection.bind(this);
  }

  render () {
    return (
      <React.Fragment>
        <th onClick={this.onClickColumnHeader}>
          {this.props.column.name}
        </th>
      </React.Fragment>
    );
  }

  onClickColumnHeader(propertyToSortOn) {
    this.props.sort(this.props.column.property, this.getSortDirection());
  }

  getSortDirection() {
    var ascValue = "asc";
    var descValue = "desc";
    var direction = this.state.sortDirection;

    if (!direction || direction === descValue) {
      direction = ascValue;
    } else {
      direction = descValue;
    }

    this.setState({ sortDirection: direction });
    return direction;
  }
}

export default ResultsTableColumnHeader
