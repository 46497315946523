import React from "react"
import PropTypes from "prop-types"
import Filter from './Filter'
import Results from './Results'
import AsyncSelect from "react-select/lib/Async"

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      results: [],
      waiting: false
    }

    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onChangeStatistic = this.onChangeStatistic.bind(this);
    this.getStatistics = this.getStatistics.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.getResults();
  }

  render () {
    return (
      <React.Fragment>
        <Filter onChangeHandler={this.onChangeFilter} onApply={this.applyFilter} onReset={this.reset}></Filter>

        <hr />

        <label className="control-label">
          Statistic
        </label>
        <AsyncSelect
          placeholder="Select a statistic"
          cacheOptions
          defaultOptions
          loadOptions={this.getStatistics}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={this.onChangeStatistic}
          className="statistic-select"
        >
        </AsyncSelect>

        <Results statistic={this.state.statistic} value={this.state.results} waiting={this.state.waiting}></Results>
      </React.Fragment>
    );
  }

  getStatistics() {
    var url = '/statistics/statistics'

    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';
      xhr.onload = function() {
          var status = xhr.status;
          if (status === 200) {
            resolve(xhr.response);
          } else {
            //callback(status, xhr.response);
          }
      }; 
      xhr.send();
    });
  }

  getResults() {
    this.setState({ waiting: true });

    var _this = this;
    var url = '/statistics/filter'
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('X-CSRF-Token', document.getElementsByName('csrf-token')[0].content);
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200) {
          _this.setState({ waiting: false });
          _this.setState({ results: xhr.response });
        } else {
          //callback(status, xhr.response);
        }
    }; 
    xhr.send(JSON.stringify({ statistic: this.state.statistic, filter: this.state.filter }));
  }

  onChangeStatistic(statistic){
    this.setState({ statistic: statistic.id }, () => this.getResults())
  }

  onChangeFilter(filterState) {
    this.setState({ filter: filterState })
  }

  applyFilter() {
    this.getResults();
  }

  reset() {
    this.setState({ results: []});
  }
}

export default Statistics
