import React from "react"
import PropTypes from "prop-types"
import { ResultTypes } from "../helpers/ResultTypes"
import ResultsTableColumnHeader from './ResultsTableColumnHeader'

class ResultsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statistic: props.statistic,
      value: props.value
    }

    this.sortOnColumn = this.sortOnColumn.bind(this);
  }

  static getDerivedStateFromProps(props, current_state) {
    var statistic = current_state.statistic !== props.statistic ? props.statistic : current_state.statistic
    var value = current_state.value !== props.value ? props.value : current_state.value
    
    return {
      statistic: statistic,
      value: value
    }
  }

  render () {
    return (
      <React.Fragment>
        { this.renderTable(this.state.statistic, this.state.value) }
      </React.Fragment>
    );
  }

  renderTable(statistic, value) {
    if (!ResultTypes.map(x => x.id).includes(statistic)){
      return;
    }

    var tableDescription = ResultTypes.filter(x => x.id == statistic)[0];

    var result = (
      <div>
        <h3>{ tableDescription.name }</h3>
        <table className="table table-hover table-condensed table-responsive">
          <thead>
            <tr>
              { 
                tableDescription.columns.map(column => {
                  return (<ResultsTableColumnHeader key={column.property} column={column} sort={this.sortOnColumn}></ResultsTableColumnHeader>)
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              value.map((x, i) => {
                return (
                  <tr key={i}>
                    {
                      tableDescription.columns.map((c, j) => {
                        var content = "-";
                        var value = x[c.property];

                        if (!!value){
                          if (c.time){
                            var minutes = value % 60; // % is the remainder after devision operator in JS
                            var hours = (value - minutes) / 60;

                            minutes = minutes < 10 ? '0' + minutes : minutes;
                            hours = hours < 10 ? '0' + hours : hours;
                            content = `${hours}u${minutes}`;
                          }
                          else if ((tableDescription.price && j != 0) || c.price) {
                            var prefix = "€ ";
                            content = `${prefix}${this.numberWithSpaces(value)}`;
                          }
                          else {
                            content = value;
                          }
                        }

                        return (
                          <td key={`${i}_${j}`}>
                            { content }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
    
    return result;
  }

  sortOnColumn(property, direction) {
    var value = this.state.value;

    var sortedValue = value.sort((a, b) => a[property] > b[property] ? 1 : (a[property] < b[property] ? -1 : 0));
    if (direction === "desc"){
      sortedValue = sortedValue.reverse();
    }

    this.setState({ value: sortedValue });
  }

  numberWithSpaces(x) {
    if (!x) {
      return "";
    }

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }
}

export default ResultsTable