import React from "react"
import PropTypes from "prop-types"
import ResultsTable from './ResultsTable'
import LoadingImage from 'images/loading.gif'

class Results extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    if (this.props.waiting) {
      return (
        <div className="loading-container">
          <img src={LoadingImage}></img>
        </div>
      )
    }
    
    return (
      <React.Fragment>
        { 
          this.props.value.map((result, i) => {
            return ( <ResultsTable key={`result_table_${i}`} statistic={result.statistic_id} value={result.results}></ResultsTable> )
          })
        }
        
      </React.Fragment>
    );
  }
}

export default Results
